<template>
  <va-button
    v-if="field && value"
    class="ml-2"
    flat
    size="small"
    icon="content_copy"
    @click="clipboardCopy(field, value)"
  />
</template>

<script setup>
import { inject } from "vue";

const clipboardCopy = inject("clipboardCopy");

defineProps({
  field: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  }
});
</script>
