export const printDate = date => {
  const formattedDate = new Date(date);
  if (!date || !formattedDate) {
    return "";
  } else {
    return formattedDate.toLocaleString();
  }
};

/**
 * Uncapitalize the first letter of a string
 * @param {string} str
 * @returns {string | undefined}
 */
export const uncapitalizeFirstLetter = str => {
  if (!str) {
    return;
  }

  return str.charAt(0).toLowerCase() + str.slice(1);
};
